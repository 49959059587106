:root {
  --large-font-size: 25px;
  --large-font-size-p: 21px;

  .large-font {
    ion-item {
      font-size: var(--large-font-size);
      line-height: 1.85;

      ion-select,
      h2 {
        font-size: var(--large-font-size);
      }

      ion-label,
      h3,
      p {
        font-size: var(--large-font-size-p);
        line-height: 1.5;
      }

      // custom elements
      .engaged-people {
        width: 80px;
        height: 80px;
        font-size: 48px;
      }

      .engagement-button {
        font-size: 80px;
        width: 80px;
        height: 80px;
      }
    }

    ion-item-divider {
      ion-label,
      h2 {
        font-size: var(--large-font-size);
      }
    }

    ion-alert {
      --max-width: 350px;
      --width: 350px;

      .alert-head {
        h2 {
          font-size: var(--large-font-size);
        }
      }

      .alert-message {
        font-size: var(--large-font-size-p);
      }

      .alert-checkbox-group {
        .alert-checkbox-label {
          font-size: var(--large-font-size-p);
        }
      }

      .alert-radio-group {
        .alert-radio-label {
          font-size: var(--large-font-size-p);
        }
      }

      .alert-button-group {
        button {
          font-size: var(--large-font-size);
        }
      }
    }

    ion-modal {
      ion-footer {
        ion-button {
          font-size: var(--large-font-size-p);
          height: 2.8em;
        }
      }
    }

    ion-searchbar {
      padding-top: 18px;
      padding-bottom: 18px;
      height: 90px;

      .searchbar-input-container {
        height: 54px;
      }

      .searchbar-input {
        font-size: var(--large-font-size);
        padding-top: 0;
        padding-bottom: 0;
        height: 100%;
      }

      .searchbar-search-icon {
        top: 0;
        height: 100%;
      }
    }

    lib-map-tools {
      lib-panel-tool-item {
        button {
          padding: 0.5rem;

          ion-icon {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  ion-button.large-font {
    font-size: var(--large-font-size);
    height: 2.8em;
  }
}
